import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Pagination } from "swiper";
import "swiper/css/pagination";
import profilePic1 from "../../img/profile1.jpg";
import profilePic2 from "../../img/profile2.jpg";
import profilePic3 from "../../img/profile3.jpg";
import profilePic4 from "../../img/profile4.jpg";

const Testimonial = () => {
  const clients = [
    {
      img: profilePic1,
      review:
        "Working with this social media agency was a game-changer for our e-commerce brand. Their creative content and targeted campaigns significantly boosted our online presence. Increased sales and a more engaged customer base. Truly a top-notch team!",
    },
    {
      img: profilePic2,
      review:
        "This agency understood our tech startup's unique needs and delivered a tailored social media strategy that exceeded our expectations. The increased visibility and positive brand image have been instrumental in our growth.",
    },
    {
      img: profilePic3,
      review:
        "From visually stunning posts to strategic influencer collaborations, this agency took our fashion boutique to new heights. Our social media following has grown, and we've seen a direct impact on foot traffic and sales. Exceptional work!",
    },
    {
      img: profilePic4,
      review:
        "Strong online presence is crucial. This agency not only understood my brand but also crafted engaging content that resonated with my audience. Their analytics and reporting were spot-on. Highly recommended!",
    },
  ];

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading">
        <span>Clients always get </span>
        <span>Exceptional Work </span>
        <span>from Us...</span>
        <div
          className="blur t-blur1"
          style={{ background: "var(--purple)" }}
        ></div>
        <div className="blur t-blur2" style={{ background: "skyblue" }}></div>
      </div>
      <Swiper
        // install Swiper modules
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} alt="" />
                <span>{client.review}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonial;
